import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  page: {
    pageTitle: "Documentation",
    logo: {
      src: {
        light: "/logos/logo.svg",
        dark: "/logos/logo-dark.svg",
      },
      width: "200px",
    },
  },
  theme: {
    light: {
      primary: "30 98% 53%",
      primaryForeground: "30 9.8% 5.300000000000001%"
    },
    dark: {
      primary: "30 98% 53%",
      primaryForeground: "30 9.8% 5.300000000000001%"
    }
  },
  topNavigation: [
    { id: "documentation", label: "Documentation" },
    { id: "api", label: "API Reference" },
  ],
  sidebar: {
    documentation: [
      {
        type: "category",
        label: "Overview",
        items: ["openapi", "feedback"],
      },
    ],
  },
  redirects: [
    { from: "/", to: "/documentation" }
  ],
  apis: {
    type: "url",
    input: "https://todo-demo-main-425451e.d2.zuplo.dev/openapi",
    navigationId: "api",
  },
  docs: {
    files: "/pages/**/*.{md,mdx}",
  },
};

export default config;